'use strict';

export const setPublicationDate = (data) => {
   const months = [
      'янв.',
      'фев.',
      'мар.',
      'апр.',
      'май',
      'июн.',
      'июл.',
      'авг.',
      'сен.',
      'окт.',
      'ноя.',
      'дек.'
   ];

   const time = new Date(data.date);

   const date = `${time.getDate()} ${months[time.getMonth()]} ${time.getFullYear()}`;
   const hours = time.getHours() < 10 ? `0${time.getHours()}` : time.getHours();
   const minutes = time.getMinutes() < 10 ? `0${time.getMinutes()}` : time.getMinutes();

   return `${date}, ${hours}:${minutes}`;
};
