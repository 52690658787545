/**
 * @return {object}
 **/
export const checkQueryParams = (routeName, query, params) => {
   let result = { name: routeName, query };

   if (Object.keys(query).length) {
      for (let key in query) {
         if (!params.includes(key) || !query[key]) {
            result = { name: 'NotFound' };
         }
      }
   } else {
      result = { name: routeName, query: { page: 1 } };
   }

   return result;
};
