<template>
   <main class="main">
      <app-breadcrumbs last-element="Блог" />

      <section class="main__blog blog">
         <div class="container">
            <h2 class="blog__section-title section-title">Блог</h2>

            <div v-if="posts.length" class="blog__wrap">
               <article v-for="post in posts" :key="post.id" class="post">
                  <img :src="post.images.path" :alt="post.title" class="post__img" />

                  <div class="post__inner">
                     <h3 class="post__title">
                        <router-link
                           :to="{ name: 'Post', params: { id: post.id } }"
                           class="post__link"
                        >
                           {{ post.title }}
                        </router-link>
                     </h3>

                     <time :datetime="post.date" class="post__date">
                        {{ post.publicationDate }}
                     </time>
                  </div>
               </article>
            </div>

            <app-notifications v-if="!posts.length" notification-type="Loading" />
         </div>
      </section>

      <app-pagination
         v-if="posts.length"
         :max-items-count-on-page="maxPostsCountOnPage"
         :items-count="postsCount"
         routeName="Blog"
      />
   </main>
</template>

<script>
   import AppBreadcrumbs from '@/components/templates/AppBreadcrumbs';
   import AppPagination from '@/components/templates/AppPagination';
   import AppNotifications from '@/components/templates/Notifications/AppNotifications';
   import { checkQueryParams } from '@/components/_utils/checkQueryParams';
   import { setPublicationDate } from '@/components/_utils/setPublicationDate';
   import { mapState, mapActions } from 'vuex';

   export default {
      name: 'Blog',

      components: {
         AppBreadcrumbs,
         AppPagination,
         AppNotifications
      },

      inject: ['backToTopOfPage'],

      data() {
         return {
            maxPostsCountOnPage: 12
         };
      },

      mounted() {
         const { name, query } = this.$route;
         const result = checkQueryParams(name, query, ['page']);

         this.$router.replace(result);

         this.backToTopOfPage();
      },

      methods: {
         ...mapActions('posts', ['fetchPosts'])
      },

      computed: {
         ...mapState('posts', ['posts', 'postsCount', 'errorMessage'])
      },

      watch: {
         errorMessage(error) {
            error?.status === 404 && this.$router.replace({ name: 'NotFound' }); //if
         },

         '$route.query.page': {
            handler(page) {
               if (page && this.$route.name === 'Blog') {
                  this.fetchPosts({ page, limit: this.maxPostsCountOnPage });
               }
            },
            immediate: true
         },

         posts(value) {
            value.forEach((post) => {
               post.images.path = require(`@/assets/images/blog/${post.images.folder}/${post.images.preview}`);
               post.publicationDate = setPublicationDate(post);
            });
         }
      }
   };
</script>
